<template>
  <div>
    <page-title />
    <b-alert
      :show="!!updateError"
      variant="danger"
      v-text="updateError"
    />
    <b-card>
      <b-form @submit.prevent="updateLogin()">
        <b-form-group
          :invalid-feedback="uniqueError"
          :state="uniqueValidationState"
        >
          <b-input-group>
            <b-form-input
              v-model="login"
              v-debounce="checkLoginUnique"
              required
              placeholder="New login"
              :state="uniqueValidationState"
              autofocus
            />
          </b-input-group>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="!canSubmit"
        >
          <b-spinner
            v-if="updating"
            small
          /> Update
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import ApiService from '@/ApiService'
import { getRequestErrorText } from '@/utils'
import PageTitle from '@/components/PageTitle'

export default {
  name: 'UpdateLogin',
  components: { PageTitle },
  data () {
    return {
      updating: false,
      updateError: '',
      login: '',
      uniqueError: ''
    }
  },
  computed: {
    canSubmit () {
      return this.login && !this.uniqueError
    },
    uniqueValidationState () {
      return this.uniqueError ? false : null
    }
  },
  watch: {
    login () {
      this.updateError = ''
      this.uniqueError = ''
    }
  },
  methods: {
    updateLogin () {
      this.updateError = ''
      this.updating = true

      ApiService.post('/user/login/update', { login: this.login })
        .then(() => {
          this.$router.push({ name: 'Home' })
        })
        .catch(error => {
          this.updateError = getRequestErrorText(error, '')
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkLoginUnique (login) {
      if (login) {
        this.uniqueError = ''

        ApiService.get(`/user/login/${login}/is-unique`)
          .catch(error => {
            this.uniqueError = getRequestErrorText(error, '')
          })
      }
    }
  }
}
</script>
